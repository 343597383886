define("discourse/plugins/discourse-siwe/discourse/templates/siwe-auth-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <form id="siwe-sign" method="POST" action="/auth/siwe/callback" style="display: none;">
    <textarea id="eth_account" name="eth_account"></textarea>
    <textarea id="eth_message" name="eth_message"></textarea>
    <textarea id="eth_signature" name="eth_signature"></textarea>
    <textarea id="eth_avatar" name="eth_avatar"></textarea>
  </form>
  <div class="eth_provider_container">
    <div class="eth_provider_auth_button">
      {{d-button
        class="btn-primary"
        action=(action "initAuth")
        label="eth_providers.buttons.start"
      }}
    </div>
  </div>
  
  */
  {
    "id": "ziJGxg1M",
    "block": "[[[10,\"form\"],[14,1,\"siwe-sign\"],[14,\"method\",\"POST\"],[14,\"action\",\"/auth/siwe/callback\"],[14,5,\"display: none;\"],[12],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"eth_account\"],[14,3,\"eth_account\"],[12],[13],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"eth_message\"],[14,3,\"eth_message\"],[12],[13],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"eth_signature\"],[14,3,\"eth_signature\"],[12],[13],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"eth_avatar\"],[14,3,\"eth_avatar\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"eth_provider_container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"eth_provider_auth_button\"],[12],[1,\"\\n    \"],[1,[28,[35,3],null,[[\"class\",\"action\",\"label\"],[\"btn-primary\",[28,[37,4],[[30,0],\"initAuth\"],null],\"eth_providers.buttons.start\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"form\",\"textarea\",\"div\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-siwe/discourse/templates/siwe-auth-index.hbs",
    "isStrictMode": false
  });
});